import { TitleSubtitle } from '@les-sherpas/sherpas-toolbox';

import Hero from '@/components/LandingMozza/common/Hero/Hero';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoHero({
  hero,
}: {
  hero: TitleSubtitle & { items: string[] };
}) {
  return (
    <Hero
      title={
        hero.title ? (
          <SEOFormattedMessage
            id="hero.overloadedTitle"
            defaultMessage={hero.title}
          />
        ) : undefined
      }
      subtitle={
        hero.subtitle ? (
          <SEOFormattedMessage
            id="hero.overloadedSubtitle"
            defaultMessage={hero.subtitle}
          />
        ) : undefined
      }
      reasons={
        hero.items.length > 0
          ? hero.items.map((reason, index) => (
              <SEOFormattedMessage
                id={`hero.reasons.overloadedReason${index}`}
                key={`reason-${index}`}
                defaultMessage={reason}
              />
            ))
          : undefined
      }
    />
  );
}
